import axios from "axios";

/**
 * Service to call dashboard endpoints
 */
const DashboardService = {
  /**
   * Get a data by observation categories
   * @param id
   * @returns {*}
   */
  observationByCategory() {
    return new Promise((resolve, reject) => {
      axios
        .get("dashboard/observations-by-category")
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data);
          } else {
            reject(new Error(error));
          }
        });
    });
  },

  /**
   * Get a data by observation categories
   * @param id
   * @returns {*}
   */
  observationByLocation() {
    return new Promise((resolve, reject) => {
      axios
        .get("dashboard/observations-by-location")
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data);
          } else {
            reject(new Error(error));
          }
        });
    });
  }
};

export default DashboardService;
