<template>
  <div class="card card-custom bg-gray-100 card-stretch gutter-b">
    <div class="card-body p-0 position-relative overflow-hidden">
      <div class="dashboard-hero">
        <h3 class="card-title font-weight-bolder text-white px-8 py-8">
          Welcome {{ currentUser.firstname }}<br />
          <small>{{ currentUser.email }}</small
          ><br /><br />
          <small
            ><span class="badge badge-info">{{
              currentUser.role.name
            }}</span></small
          >
        </h3>
      </div>
      <div class="card-spacer mt-n25">
        <div class="row m-0">
          <div
            @click="goto('energy-logging')"
            class="col bg-light-success px-6 py-8 rounded-xl mr-7 mb-7 cursor-pointer"
          >
            <span class="svg-icon svg-icon-3x svg-icon-success d-block my-2">
              <inline-svg src="media/svg/icons/General/Thunder.svg" />
            </span>
            <a class="text-success font-weight-bold font-size-h6">
              Energy
            </a>
          </div>
          <div
            @click="goto('waste-logging')"
            class="col bg-light-danger px-6 py-8 rounded-xl mb-7 cursor-pointer"
          >
            <span class="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
              <inline-svg src="media/svg/icons/Home/Trash.svg" />
            </span>
            <a class="text-danger font-weight-bold font-size-h6 mt-2">
              Waste
            </a>
          </div>
        </div>
        <div class="row m-0">
          <div
            @click="goto('observations')"
            class="col bg-primary px-6 py-8 rounded-xl shadow cursor-pointer"
          >
            <span class="svg-icon svg-icon-3x svg-icon-white d-block my-2">
              <inline-svg src="media/svg/icons/General/Binocular.svg" />
            </span>
            <a class="text-white font-weight-bold font-size-h6 mt-2">
              Observer Module
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "dashboard-quicklinks",
  components: {},
  data() {
    return {
      strokeColor: "#D13647",
      chartOptions: {},
      series: [
        {
          name: "Net Profit",
          data: [30, 45, 32, 70, 40, 40, 40]
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["layoutConfig", "currentUser"])
  },
  mounted() {},
  methods: {
    goto(route) {
      this.$router.push({ name: route });
    }
  }
};
</script>
