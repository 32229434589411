var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xxl-4"},[_c('dashboard-quicklinks')],1),_c('div',{staticClass:"col-xxl-8"},[_c('div',{staticClass:"card card-custom card-stretch gutter-b"},[_vm._m(0),_c('div',{staticClass:"card-body pt-2"},[(_vm.loaded)?_c('bar-chart',{attrs:{"chartdata":_vm.categoryData,"options":_vm.options}}):_vm._e(),(!_vm.loaded)?_c('div',{staticClass:"container h-100"},[_c('div',{staticClass:"row h-100 justify-content-center align-items-center"},[_c('div',{staticClass:"col-12 text-center"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/shopping/chart-bar2.svg"}}),_c('h5',{staticClass:"text-center text-muted"},[_vm._v("No Chart Data")])],1)])]):_vm._e()],1)])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xxl-12"},[_c('div',{staticClass:"card card-custom card-stretch gutter-b"},[_c('div',{staticClass:"card-header border-0"},[_c('h3',{staticClass:"card-title font-weight-bolder text-dark"},[_vm._v(" Observations Assigned To Me ")]),_c('div',{staticClass:"card-toolbar"},[_c('router-link',{attrs:{"to":"/observations/assigned"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"variant":"primary","title":"See All Assigned"},on:{"click":navigate}},[_vm._v("See All Assigned")])]}}])})],1)]),_c('div',{staticClass:"card-body pt-2"},[_c('hr'),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.observations,"loading":_vm.dataLoading,"loading-text":"Loading Observations...","footer-props":{
              'items-per-page-options': [10, 20, 30, 40, 50]
            },"items-per-page":20},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
            var item = ref.item;
return [_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(item.title),expression:"item.title",modifiers:{"hover":true,"top":true}}],staticClass:"d-inline-block text-truncate cursor-pointer",staticStyle:{"max-width":"100px"}},[_vm._v(" "+_vm._s(item.title)+" ")])]}},{key:"item.status",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"label label-inline",class:_vm.getColor(item.status.name)},[_vm._v(_vm._s(item.status.name))])]}},{key:"item.user",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.firstname)+" "+_vm._s(item.user.lastname)+" ")]}},{key:"item.timestamp",fn:function(ref){
            var item = ref.item;
return [_c('span',[_c('time-ago',{staticClass:"text-sm",attrs:{"refresh":60,"datetime":item.timestamp,"locale":"en","long":true,"tooltip":""}})],1)]}},{key:"item.action",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"w-100 text-right"},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Observation Details'),expression:"'Observation Details'",modifiers:{"hover":true,"top":true}}],staticClass:"btn btn-icon btn-light-primary btn-hover-light-primary btn-sm",on:{"click":function($event){return _vm.itemDetails(item.id)}}},[_c('span',{staticClass:"svg-icon svg-icon-md svg-icon-primary"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Layout/Layout-horizontal.svg"}})],1)]),(_vm.hasPermission('Administrator, Editor'))?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Edit Observation'),expression:"'Edit Observation'",modifiers:{"hover":true,"top":true}}],staticClass:"btn btn-icon btn-light-warning btn-hover-light-warning btn-sm ml-3",on:{"click":function($event){return _vm.editItem(item.id)}}},[_c('span',{staticClass:"svg-icon svg-icon-md svg-icon-warning"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Communication/Write.svg"}})],1)]):_vm._e(),(_vm.hasPermission('Administrator, Editor'))?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Delete Observation'),expression:"'Delete Observation'",modifiers:{"hover":true,"top":true}}],staticClass:"btn btn-icon btn-light-danger btn-hover-light-danger btn-sm ml-3",on:{"click":function($event){return _vm.deleteItem(item.id)}}},[_c('span',{staticClass:"svg-icon svg-icon-md svg-icon-danger"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/General/Trash.svg"}})],1)]):_vm._e()])]}}],null,true)})],1)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header border-0"},[_c('h3',{staticClass:"card-title font-weight-bolder text-dark"},[_vm._v(" Observations By Categories ")]),_c('div',{staticClass:"card-toolbar"})])}]

export { render, staticRenderFns }