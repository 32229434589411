<template>
  <div>
    <div class="row">
      <div class="col-xxl-4">
        <dashboard-quicklinks></dashboard-quicklinks>
      </div>
      <div class="col-xxl-8">
        <div class="card card-custom card-stretch gutter-b">
          <div class="card-header border-0">
            <h3 class="card-title font-weight-bolder text-dark">
              Observations By Categories
            </h3>
            <div class="card-toolbar"></div>
          </div>
          <div class="card-body pt-2">
            <bar-chart
              v-if="loaded"
              :chartdata="categoryData"
              :options="options"
            />
            <div v-if="!loaded" class="container h-100">
              <div class="row h-100 justify-content-center align-items-center">
                <div class="col-12 text-center">
                  <inline-svg src="media/svg/icons/shopping/chart-bar2.svg" />
                  <h5 class="text-center text-muted">No Chart Data</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xxl-12">
        <div class="card card-custom card-stretch gutter-b">
          <div class="card-header border-0">
            <h3 class="card-title font-weight-bolder text-dark">
              Observations Assigned To Me
            </h3>
            <div class="card-toolbar">
              <router-link to="/observations/assigned" v-slot="{ navigate }">
                <b-button
                  variant="primary"
                  v-b-tooltip.hover
                  title="See All Assigned"
                  @click="navigate"
                  >See All Assigned</b-button
                >
              </router-link>
            </div>
          </div>
          <div class="card-body pt-2">
            <hr />
            <v-data-table
              :headers="headers"
              :items="observations"
              :loading="dataLoading"
              loading-text="Loading Observations..."
              :footer-props="{
                'items-per-page-options': [10, 20, 30, 40, 50]
              }"
              :items-per-page="20"
              class="elevation-1"
            >
              <template v-slot:[`item.title`]="{ item }">
                <span
                  v-b-tooltip.hover.top="item.title"
                  class="d-inline-block text-truncate cursor-pointer"
                  style="max-width: 100px;"
                >
                  {{ item.title }}
                </span>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <span
                  class="label label-inline"
                  :class="getColor(item.status.name)"
                  >{{ item.status.name }}</span
                >
              </template>

              <template v-slot:[`item.user`]="{ item }">
                {{ item.user.firstname }} {{ item.user.lastname }}
              </template>

              <template v-slot:[`item.timestamp`]="{ item }">
                <span
                  ><time-ago
                    class="text-sm"
                    :refresh="60"
                    :datetime="item.timestamp"
                    locale="en"
                    :long="true"
                    tooltip
                  ></time-ago
                ></span>
              </template>

              <template v-slot:[`item.action`]="{ item }">
                <div class="w-100 text-right">
                  <span
                    @click="itemDetails(item.id)"
                    v-b-tooltip.hover.top="'Observation Details'"
                    class="btn btn-icon btn-light-primary btn-hover-light-primary btn-sm"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <inline-svg
                        src="media/svg/icons/Layout/Layout-horizontal.svg"
                      ></inline-svg>
                    </span>
                  </span>
                  <span
                    @click="editItem(item.id)"
                    v-if="hasPermission('Administrator, Editor')"
                    v-b-tooltip.hover.top="'Edit Observation'"
                    class="btn btn-icon btn-light-warning btn-hover-light-warning btn-sm ml-3"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-warning">
                      <inline-svg
                        src="media/svg/icons/Communication/Write.svg"
                      ></inline-svg>
                    </span>
                  </span>
                  <span
                    @click="deleteItem(item.id)"
                    v-if="hasPermission('Administrator, Editor')"
                    v-b-tooltip.hover.top="'Delete Observation'"
                    class="btn btn-icon btn-light-danger btn-hover-light-danger btn-sm ml-3"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-danger">
                      <inline-svg
                        src="media/svg/icons/General/Trash.svg"
                      ></inline-svg>
                    </span>
                  </span>
                </div>
              </template>
            </v-data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import ObservationService from "@/core/services/observation.service.js";
import DashboardQuicklinks from "@/view/content/widgets/dashboard/quicklinks.vue";
import DashboardService from "@/core/services/dashboard.service.js";
import BarChart from "@/view/content/widgets/dashboard/bar-chat.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { TimeAgo } from "vue2-timeago";

export default {
  name: "dashboard",
  components: {
    "dashboard-quicklinks": DashboardQuicklinks,
    "bar-chart": BarChart,
    TimeAgo
  },
  computed: {
    /**
     * Check if has title
     * @returns {boolean}
     */
    hasTitleSlot() {
      return !!this.$slots["title"];
    },
    ...mapGetters(["currentUser", "layoutConfig", "hasPermission"])
  },
  mounted() {
    this.getObservations();
    this.getByObservation();
    this.getByLocation();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
  },
  methods: {
    getByObservation() {
      this.loaded = false;
      DashboardService.observationByCategory()
        .then(response => {
          if (response.data.classifications.length > 0) {
            this.categoryData = {
              labels: response.data.classifications,
              datasets: [
                {
                  label: "Observation Count",
                  backgroundColor: "#3699ff",
                  data: response.data.count
                }
              ]
            };
            this.loaded = true;
          }
        })
        .catch(error => {
          this.makeToast(
            "danger",
            "Oops",
            "An unexpected exception has occured. Pilot Could not populate chart data, please give it a minute and refresh page"
          );
          console.log(error);
        });
    },
    getByLocation() {
      this.loaded = false;
      DashboardService.observationByLocation()
        .then(response => {
          this.locationData = {
            labels: response.data.locations,
            datasets: [
              {
                label: "Observations By Location",
                backgroundColor: "#f87979",
                data: response.data.count
              }
            ]
          };
        })
        .catch(error => {
          this.makeToast(
            "danger",
            "Oops",
            "An unexpected exception has occured. Could not populate observation types at the moment, please give it a minute and refresh page"
          );
          console.log(error);
        });
    },
    makeToast(variant = null, title, message) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true
      });
    },
    getObservations() {
      let slug = `?assigned=${true}`;
      ObservationService.all(slug)
        .then(response => {
          this.observations = response.data;
          this.dataLoading = false;
        })
        .catch(error => {
          this.makeToast(
            "danger",
            "Oops",
            "An unexpected exception has occured. Could not populate observations at the moment, please give it a minute and refresh page"
          );
          this.dataLoading = false;
          console.log(error);
        });
    },
    editItem(id) {
      this.$router.push(`/observations/edit/${id}`);
    },
    itemDetails(id) {
      this.$router.push(`/observations/details/${id}`);
    },
    deleteItem(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "Are you sure you want to delete this observation",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Delete",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return ObservationService.delete(id)
            .then(response => {
              // eslint-disable-next-line prettier/prettier
              let slug = `?assigned=${true}`;
              this.getObservations(slug);
              return response.data;
            })
            .catch(error => {
              Swal.showValidationMessage(
                `Operation Failed: ${error.data.message}`
              );
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then(result => {
        if (result.isConfirmed) {
          this.makeToast(
            "success",
            "Observation Deleted",
            "Observation was successfully deleted"
          );
        }
      });
    },
    getColor(status) {
      console.log(status);
      if (status == "Open") return "label-light-danger";
      else if (status == "Pending") return "label-light-warning";
      else return "label-light-success";
    }
  },
  data: () => ({
    loaded: false,
    categoryData: null,
    locationData: null,
    options: { maintainAspectRatio: false },
    fields: {
      "Observation Title": "title",
      "Location ": "location.name",
      "Classification ": "classification.name",
      "Type ": "observation_type.name",
      "Timestamp ": "timestamp",
      "Status ": "status.name",
      "Assigned To": {
        field: "action_party_users",
        callback: value => {
          return `${value.firstname} ${value.lastname}`;
        }
      },
      "Action Party Group": "action_party_groups.name",
      "Description ": "description",
      "Action Taken ": "action_taken",
      "Recommendation ": "recommendation"
    },
    headers: [
      {
        text: "Observation",
        align: "start",
        sortable: false,
        value: "title"
      },
      { text: "Location", value: "location.name" },
      { text: "Category", value: "classification.name" },
      { text: "Type", value: "observation_type.name" },
      { text: "Timestamp", value: "timestamp" },
      { text: "Status", value: "status" },
      { text: "Created By", value: "user" },
      { text: "", value: "action" }
    ],
    observations: [],
    rowsPerPageItems: [20, 40, 80, 100],
    dataLoading: true
  })
};
</script>
